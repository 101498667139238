import 'iconify-icon'
import 'bootstrap'
import { Turbo } from '@hotwired/turbo-rails'
import { start } from '@sonicgarden/rails-ujs-compat'
import { initClipboardCopy } from '@/src/lib/clipboardCopy'
import { startExpo } from '@/src/lib/expo'
import { startFlash } from '@/src/lib/flash'
import 'webcomponent-qr-code'
import '@github/clipboard-copy-element'
import '@sonicgarden/auto-submit-form-element'
import { initTomSelect } from '@/src/lib/tomSelect'

Turbo.session.drive = false

start()
startFlash()
startExpo()
initTomSelect()
initClipboardCopy()
