import { scriptJsonData } from '@/src/lib/json'
import { openError, openSuccess } from '@/src/lib/toast'

const SCRIPT_JSON_FLASH_ID = 'json-flash'

type FlashData = {
  notice?: string
  alert?: string
}

const isBackForwardNavigation = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return performance.getEntriesByType('navigation').some((entry) => (entry as any).type === 'back_forward')
}

export const startFlash = () => {
  document.addEventListener(
    'DOMContentLoaded',
    () => {
      if (isBackForwardNavigation()) return

      const flashData = scriptJsonData<FlashData>(SCRIPT_JSON_FLASH_ID)
      if (!flashData) return

      if (flashData.notice) {
        openSuccess(flashData.notice)
      }

      if (flashData.alert) {
        openError(flashData.alert)
      }
    },
    { once: true },
  )
}
