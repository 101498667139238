import { openToast } from '@sonicgarden/bs-toast-element'

// TODO: ユーザ画面と管理画面で上手くスタイルを切り替えれるようにする

export const openSuccess = (message: string): void => {
  openToast(message, { color: 'white', bgColor: 'dark', delay: 3000, position: 'bottom-center' })
}

export const openError = (message: string): void => {
  openToast(message, { bgColor: 'danger', delay: 10_000, position: 'bottom-center' })
}

export const openSnackbar = (message: string): void => {
  openToast(message, { color: 'white', bgColor: 'dark', delay: 1500, position: 'bottom-center' })
}
