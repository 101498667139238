import { onConnect } from '@sonicgarden/onconnect'
import TomSelect from 'tom-select'

export const initTomSelect = (): void => {
  onConnect('[data-tom-select]', (element) => {
    const select = new TomSelect(element as HTMLSelectElement, {
      searchField: ['text', 'searchString'],
      // NOTE: 元の表示順を維持するための設定
      // SEE: https://tom-select.js.org/docs/
      sortField: [{ field: '$order' }, { field: '$score' }],
    })

    return () => {
      select.destroy()
    }
  })

  onConnect('[data-tom-select-multi]', (element) => {
    const select = new TomSelect(element as HTMLSelectElement, {
      searchField: ['text', 'searchString'],
      // NOTE: 元の表示順を維持するための設定
      // SEE: https://tom-select.js.org/docs/
      sortField: [{ field: '$order' }, { field: '$score' }],
      maxItems: undefined,
      onItemAdd() {
        select.setTextboxValue('')
        select.refreshOptions()
      },
    })

    return () => {
      select.destroy()
    }
  })
}
