type RemoveListener = () => void
type ListenerArgs<E extends HTMLElement> = {
  event: Event
  currentTarget: E
}
type Listener<E extends HTMLElement> = (args: ListenerArgs<E>) => void

export const delegateEvent = <E extends HTMLElement>(
  type: string,
  selectors: string,
  listener: Listener<E>,
): RemoveListener => {
  const func = (event: Event) => {
    if (!(event.target instanceof HTMLElement)) return

    const currentTarget = event.target.closest<E>(selectors)
    if (!currentTarget) return

    listener({ event, currentTarget })
  }

  document.addEventListener(type, func)

  return () => {
    document.removeEventListener(type, func)
  }
}
