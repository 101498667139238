import type { FrameElement } from '@hotwired/turbo'
import { Modal } from 'bootstrap'
import { delegateEvent } from '@/src/lib/delegateEvent'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postMessage = (type: string, payload: any = {}) => {
  const data = { type, payload }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).ReactNativeWebView?.postMessage(JSON.stringify(data))
}

const showNotfoundModal = () => {
  const element = document.querySelector('[data-expo-notfound-modal]')
  if (!element) throw new Error("can't find [data-expo-notfound-modal]")

  Modal.getOrCreateInstance(element).show()
}

let loaded = false
let searchBTTimer: number | undefined

const initExpo = () => {
  // NOTE: expo側から2回呼ばれることがあるための対策
  if (loaded) return

  loaded = true

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.querySelector('html')!.classList.add('expo')

  const startButton = document.querySelector<HTMLElement>('[data-expo-start-button]')

  if (startButton) {
    const universityId = startButton.dataset.expoStartButton
    postMessage('searchBT', { universityId })
    searchBTTimer = setTimeout(() => {
      postMessage('stopSearchBT')
      showNotfoundModal()
    }, 5000)
  }

  delegateEvent<HTMLAnchorElement>('click', 'a[data-expo-survey]', ({ event, currentTarget }) => {
    event.preventDefault()
    const url = currentTarget.href
    postMessage('openSurvey', { url })
  })

  delegateEvent<HTMLAnchorElement>('click', 'a[data-expo-browser]', ({ event, currentTarget }) => {
    event.preventDefault()
    const url = currentTarget.href
    postMessage('openBrowser', { url })
  })

  delegateEvent<HTMLElement>('click', '[data-expo-method]', ({ event, currentTarget }) => {
    event.preventDefault()
    const method = currentTarget.dataset.expoMethod
    if (!method) return

    postMessage('method', { method })
  })

  delegateEvent<HTMLElement>('click', '[data-expo-line-login]', ({ event, currentTarget }) => {
    event.preventDefault()
    const channelId = currentTarget.dataset.expoLineLogin
    if (!channelId) return

    postMessage('loginWithLine', { channelId })
  })

  delegateEvent<HTMLButtonElement>('click', '[data-expo-start-button]', ({ event, currentTarget }) => {
    event.preventDefault()
    currentTarget.disabled = true

    postMessage('method', { method: 'startBot' })
  })
}

const foundSwitchBot = (url: string) => {
  clearTimeout(searchBTTimer)
  const frame = document.querySelector<FrameElement>('turbo-frame#search-printers')
  if (!frame) return

  frame.src = url
  frame.disabled = false
}

type Message = {
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
}

// NOTE: window.postMessageは他でも利用されているのでパース出来ないケースがあるのは仕方ない
const SafeParseMessage = (json: string): Message | undefined => {
  try {
    const { type, payload } = JSON.parse(json)
    return typeof type === 'string' ? { type, payload } : undefined
  } catch {
    return undefined
  }
}

export const startExpo = (): void => {
  window.addEventListener(
    'message',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ data }: any) => {
      const { type, payload } = SafeParseMessage(data) ?? {}
      switch (type) {
        case 'initExpo': {
          initExpo()
          break
        }
        case 'foundSwitchBot': {
          foundSwitchBot(payload.url)
          break
        }
        case 'startedSwitchBot': {
          const element = document.querySelector('[data-expo-start-modal]')
          if (!element) {
            // NOTE: 別画面に遷移後に呼ばれる可能性を考慮している
            return
          }

          Modal.getOrCreateInstance(element).show()

          const startButton = document.querySelector<HTMLButtonElement>('[data-expo-start-button]')
          if (startButton) {
            startButton.disabled = false
          }
          break
        }
        default: {
          break
        }
      }
    },
    // NOTE: Androidでも動作させるためには指定が必要
    // SEE: https://github.com/react-native-webview/react-native-webview/issues/356
    true,
  )
}
